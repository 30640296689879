<template>
	<v-dialog>
		<v-card color="white" class="modal-container">
			<v-card-title class="mb-3 text-center"> Linking Disclaimer </v-card-title>
			<v-card-text class="text-secondary">
				<LinkingContent />
			</v-card-text>
			<v-card-actions class="mt-4">
				<v-btn variant="flat" class="primary" text="Close" @click="$emit('closeDialog')"></v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import LinkingContent from '@/components/LinkingContent.vue'
export default {
	components: {
		LinkingContent
	}
}
</script>

<style lang="scss" scoped>
.modal-container {
	height: auto;
	width: 600px;
	margin: 0 auto;
	padding: 16px;
	border-radius: 12px !important;

	.info-icon {
		background: $primary-100;
		color: $primary-600;
		padding: 24px;
		border-radius: 50%;
	}
	:deep(.v-toolbar__prepend) {
		margin-inline-start: 0;
	}
	.v-card-text,
	.v-card-title,
	.v-card-actions {
		padding: 0 !important;
	}

	.v-btn {
		width: 100%;
		height: 44px;
	}

	.v-btn__content {
		color: #757575;
	}
}

@media only screen and (max-width: 600px) {
	.modal-container {
		width: 100%;
		max-width: 343px;
	}
}
</style>
